import BasePlugin from '../BasePlugin'

export default class AddInvestmentRequests extends BasePlugin {
  async execute () {
    let me = this
    me.context.getParentContext().getCard().setLoading(true)
    let programScenarioId = this.context.getParentContext().getModel().id
    let gridik = this.context.getDashboardComponents()['component_2a2bc44f-7e65-49df-a9eb-055ed9268aa3'][0]
    let ids = []
    Object.values(gridik.$refs.table.selected).forEach(function (item) {
      ids.push(item.id)
    })
    this.context.$msgbox.close()
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/InvestmentProgramScenario/AddInvestmentCommand`,
      { 'investmentProgramScenarioId': programScenarioId, 'ids': ids },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      me.context.getParentContext().getCard().setLoading(false)
      this.context.getParentContext().getDashboardComponents()['component_7e86a0e7-6774-438d-8c28-6c9d3073617c'][0].loadData()
    })
  }
}
